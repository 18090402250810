const terns = [
    {
        "ttile": "Acceptance of Terms",
        "description": "By accessing and using the M.A.R.K. platform, you agree to be bound by the following terms and conditions. If you do not agree with these terms, please discontinue your use of the website."
    },
    {
        "ttile": "General Information",
        "description": "M.A.R.K. provides market analysis, predictions, and research based on historical cryptocurrency data using machine learning models. Our services are for educational and informational purposes only and should not be construed as financial advice. The predictions offered are estimates, and users should take full responsibility for their financial decisions based on this information."
    },
    {
        "ttile": "User Responsibilities",
        "description": "Users must provide accurate and up-to-date information about their cryptocurrency holdings when using our platform's portfolio tools. The predictions generated by M.A.R.K. are estimates and are not guaranteed to be accurate. Users are encouraged to use the data as a reference point and make independent decisions. All users must ensure they comply with relevant local, national, and international laws related to cryptocurrency trading."
    },
    {
        "ttile": "No Financial Advice",
        "description": "The information provided on M.A.R.K. is intended to be used for research and educational purposes. We are not licensed financial advisors, and the content on this platform should not be interpreted as investment advice or recommendations. Before making any investment decisions, we strongly encourage users to conduct their own research or consult with a professional financial advisor."
    },
    {
        "ttile": "Limitation of Liability",
        "description": "M.A.R.K. and its creators shall not be held liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use the platform, or for any inaccuracies in predictions or data presented. Users assume full responsibility for any financial outcomes that result from their use of our services."
    },
    {
        "ttile": "Privacy",
        "description": "We are committed to protecting your privacy. Any personal or financial information you provide will be handled in accordance with our Privacy Policy. We will not share, sell, or distribute your data to third parties without your consent."
    },
    {
        "ttile": "Modifications to the Terms",
        "description": "M.A.R.K. reserves the right to update or modify these terms and conditions at any time without prior notice. Users are encouraged to review these terms periodically to stay informed of any changes."
    },
    {
        "ttile": "Governing Law",
        "description": "These terms and conditions are governed by and construed in accordance with the laws of Nepal, without regard to its conflict of law principles. Any disputes arising out of the use of this platform shall be subject to the exclusive jurisdiction of the courts in Nepal."
    }
];

export default terns;
